@media screen and (max-width: 1100px){
    .referenceBoxContent{
        max-width: 434px !important;
        padding: 0 8px;
    }
    .eshopBoxContent{
        display: flex !important;
        max-width: 434px !important;
        flex-direction: column;
        padding: 0 8px;
    }
    .eshopLine{
        margin-top: 62px;
    }
    .eshopButtonBox{
        justify-content: start !important;
        margin: 48px 0;
    }
    .eshopCartBox{
        margin-top: 0px !important;
    }
    #container{
        display: none !important;
    }
    .contentInfoBox{
        max-width: 434px !important;
    }
    .contactContent{
        display: flex !important;
        flex-direction: column;
        align-items: center;
        padding: 0 8px;
    }
    .helpContent{
        display: flex !important;
        max-width: 434px !important;
        flex-direction: column;
        padding: 0 8px;
    }
    .bottomBoxContent{
        display: flex !important;
        max-width: 434px !important;
        flex-direction: column;
        align-items: start !important;
        gap: 8px;
    }
    .flexBoxDesktop{
        display: none !important;
    }
    .menuBarHotlineDesktop{
        display: none !important;
    }
    .content{
        max-width: 434px !important;
        padding: 0 16px;
    }
    .sevriceBoxsContentDesktop{
        display: none !important;
    }
    .eshopButton{
        padding: 8px !important;
    }
    .companyName {
        margin-bottom: 15px;
        height: 50px;
    }
    .content {
        margin: 0 !important;
    }
    .bottomBoxContent {
        margin: 0 !important;
    }
    .bubblesLogo {
        height: 300px;
        margin: 114px 0 204px 0 !important;
        left: calc(50% - 140px) !important;
    }
    .bubble11{
        display: none;
    }
    .bubble4{
        display: none;
    }
    .copyrightLinksBox {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }
}
@media screen and (min-width: 1101px){
    .mobileNextClients{
        display: none !important;
    }
    .menuMobile{
        display: none !important;
    }
    .sevriceBoxsContentMobile{
        display: none !important;
    }
    .serviceExpertise{
        height: 295px !important;
        width:  500px !important;
    }
    .serviceResource{
        height: 295px !important;
        width:  500px !important;
    }
    .serviceWater{
        height: 295px !important;
        width:  500px !important;
    }
    .serviceService{
        height: 295px !important;
        width:  500px !important;
    }
    .sevriceBoxsContentDesktop{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

@media screen and (max-width: 1240px){
    .bubble12{
        display: none;
    }
}

@media screen and (max-width: 820px){
    .bubble9{
        display: none;
    }
}

@media screen and (max-width: 820px){
    .bubble7{
        left: calc(50% + 100px)!important;
    }
    .bubble8 {
        left: calc(50% + 100px)!important;
    }
    .bubble10 {
        left: calc(50% + 150px) !important;
    }
    .bubble6 {
        left: calc(50% + 20px) !important;
    }
    .bubble5 {
        left: calc(50% - 250px) !important;
    }
    .bubble3 {
        left: calc(50% - 160px) !important;
    }
    .bubble2 {
        left: calc(50% - 270px) !important;
    }
    .bubble1 {
        left: calc(50% - 200px) !important;
    }
}

@media screen and (max-width: 460px){
    .bubble10{
        display: none;
    }
}



@keyframes fadeInWait {
    50% { opacity: 0; visibility: hidden; }
    100% { opacity: 1; visibility: visible; }
}

.lazyLoading{
    animation: fadeInWait 1s ease 0s;
    animation-fill-mode: forwards;

    visibility: hidden;
}

a {
    text-decoration: none !important;
    color: white !important;
}
@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

.menuBar{
    background-color: #1f1d5c;
    width: 100%;
    height: 120px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: sticky;
    z-index: 2;
    top:0;
}

.content{
    width: 100%;
    margin: 0 100px;
    max-width: 1308px;
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
}

.companyName{
    margin-bottom: 15px;
    cursor: pointer;
}

.menuBarButtonMobile{
    padding: 4px 0px;
    font-family: DirectorsGothic;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.menuBarButton{
    padding: 4px 0px;
    font-family: DirectorsGothic;
    display: flex;
    align-items: center;
}
.menuBarButton:hover{
    padding-bottom: 4px;
    padding-top: 5px;
    color: #82c1c8;
    border-bottom: solid 1px #82c1c8;
    cursor: pointer;
    font-family: DirectorsGothic;
    display: flex;
    align-items: center;
}

.menuBarButtonFocus{
    padding-bottom: 4px;
    padding-top: 5px;
    color: #82c1c8;
    border-bottom: solid 1px #82c1c8;
    cursor: pointer;
    font-family: DirectorsGothic;
    display: flex;
    align-items: center;
}

.menuBarEshopButton{
    padding: 11px;
    cursor: pointer;
    background-color: #e64e0e;
    font-family: DirectorsGothic;
    display: flex;
    align-items: center;
}

.flexBoxDesktop{
    display: flex;
    gap: 16px;
}

.flexBox{
    display: flex;
    gap: 16px;
}

.menuWrapperMobile{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.menuBoxMobileOutside{
    background-color: #1f1d5c;
    opacity: 0.7;
    width: 100%;
}

.menuBoxMobile{
    width: 100%;
    max-width: 300px;
    background-color: #afb6be;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 24px;
    padding-top: 16px;
}

.plr{
    padding-left: 32px;
}

.heroButtonBox{
    background-color: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: 600px auto;
    cursor: pointer;
}

.heroButtonBoxAnimation {
    animation: 2s ease-in 0s 1 heroButtonInitial;
}

@keyframes heroButtonInitial {
    0% {
        opacity: 0%;
    }
    50% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes infiniteMoving {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}

.heroButton{
    rotate: 90deg;
    cursor: pointer;
    animation: infiniteMoving 2s infinite;
}

@keyframes slideInFromBottom {
    0% {
        visibility: hidden;
        transform: translateY(5000%);
    }
    20% {
        visibility: visible;
        transform: translateY(5000%);
    }
    100% {
        transform: translateY(0);
    }
}

.logoAnimation{
    animation: fadeIn 1.5s;
}

.bubblesLogo{
    position: absolute;
    left: calc(50% - 160px);
    margin: 64px 0 154px 0;
}

.bubble1{
    position: absolute;
    left: calc(50% - 270px);
    top: 172px;
}

.bubble2{
    position: absolute;
    left: calc(50% - 360px);
    top: 345.594px;
}

.bubble3{
    position: absolute;
    left: calc(50% - 220px);
    top: 475.195px;
}

.bubble4{
    position: absolute;
    left: calc(50% - 450px);
    top: 518.398px;
}

.bubble5{
    position: absolute;
    left: calc(50% - 300px);
    top: 604.797px;
}

.bubble6{
    position: absolute;
    left: calc(50% + 170px);
    top: 691.195px;
}

.bubble7{
    position: absolute;
    left: calc(50% + 170px);
    top: 172.797px;
}

.bubble8{
    position: absolute;
    left: calc(50% + 240px);
    top: 259.195px;
}

.bubble9{
    position: absolute;
    left: calc(50% + 340px);
    top: 86.3984px;
    overflow-x: hidden;
}

.bubble10{
    position: absolute;
    left: calc(50% + 270px);
    top: 518.398px;
}

.bubble11{
    position: absolute;
    left: calc(50% + 440px);
    top: 648px;
}

.bubble12{
    position: absolute;
    left: calc(50% + 540px);
    top: 259.195px;
}

.bubble13{
    position: absolute;
    left: calc(50% - 540px);
    top: 30%;
}

.bubblesAnimation {
    animation: slideInFromBottom ease 3s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.bubblesBox{
    background-image: url("./images/bubbles-background.svg");
    background-position: bottom;
    background-size: cover;
    width: 100%;
    height: 620px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.sevriceTitle{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    width: 100%;
    font-family: DirectorsGothic;
    color: #82c1c8;
    border-bottom: solid 1px #e4e6e9;
    scroll-margin-top: 140px;
}

.sevriceBoxs{
    display: flex;
    justify-content: center;
}

.sevriceBoxsContentDesktop{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    max-width: 1008px;
    min-width: 360px;
    width: 100%;
    height: 100%;
    margin-left: 179px;
    margin-right: 179px;
}

.sevriceBoxsContentMobile{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    max-width: 1008px;
    min-width: 360px;
    width: 100%;
    height: 100%;
    margin-left: 179px;
    margin-right: 179px;
}

.serviceExpertise{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url("./images/odbornost.png");
    background-size: cover;
    height: 212px;
    width:  360px;
    cursor: pointer;
}

.serviceResource{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url("./images/prostredky.png");
    background-size: cover;
    height: 212px;
    width:  360px;
    cursor: pointer;
}

.serviceWater{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url("./images/voda.png");
    background-size: cover;
    height: 212px;
    width:  360px;
    cursor: pointer;
}

.serviceService{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url("./images/servis.png");
    background-size: cover;
    height: 212px;
    width:  360px;
    cursor: pointer;
}

.servicePlusButton{
    margin-bottom: 24px;
    transition: transform 0.5s;
    transform: rotate(0deg);
    cursor: pointer;
}

@keyframes spin {
    0% {
        transform:rotate(0deg);
    }
    70% {
        transform:rotate(180deg);
    }
    100% {
        transform:rotate(90deg);
    }
}

.serviceExpertise:hover .servicePlusButton{
    animation: spin 1s 1;
}

.serviceResource:hover .servicePlusButton{
    animation: spin 1s 1;
}
.serviceWater:hover .servicePlusButton{
    animation: spin 1s 1;
}
.serviceService:hover .servicePlusButton{
    animation: spin 1s 1;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.serviceTextMobile{
    max-width: 360px;
    padding: 28px 8px;
}

.crossRotation{
    transition: transform 0.5s;
    transform: rotate(45deg);
}

.eshopBox{
    display: flex;
    justify-content: center;
    margin-top: 112px;
    width: 100%;
    height: 450px;
    background-image: url("./images/shop_bg.svg");
    background-position: center;
}

.eshopBoxContent{
    max-width: 1008px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.eshopTextBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.eshopLine{
    height: 8px;
    width: 52px;
    background-color: white;
}

.eshopHover{
    animation: infiniteMoving 2s infinite;
}

.eshopTextMain{
    color: #1f1d5c;
    font-size: 42px;
    font-family: DirectorsGothic;
    margin-top: 20px;
}

.eshopCartBox{
    display: flex;
    flex-direction: row;
    margin-top: 52px;
}

.eshopCartImage{
    margin-right: 20px;
}

.eshopText{
    color: #1f1d5c;
    font-size: 24px;
    font-family: MontserratRegular;
    padding-left: 20px;
    border-left: solid 1px white;
}

.eshopButtonBox{
    display: flex;
    align-items: center;
    justify-content: center;
}

.eshopButton{
    display: flex;
    align-items: center;
    gap: 32px;
    background-color: #1f1d5c;
    color: white;
    font-family: DirectorsGothic;
    font-size: 24px;
    padding: 16px;
    border-radius: 32px;
    cursor: pointer;
}

.eshopButtonText{
    margin-top: 4px;
    margin-left: 26px;
}

.referenceBox{
    display: flex;
    justify-content: center;
    padding-top: 112px;
    width: 100%;
    scroll-margin-top: 24px;
}

.referenceBoxContent{
    max-width: 1008px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.referenceLine{
    height: 8px;
    width: 52px;
    background-color: #afb6be;
    margin-bottom: 20px;
}

.referenceTextMain{
    color: #1f1d5c;
    font-size: 42px;
    font-family: DirectorsGothic;
    margin-bottom: 88px;
}

.referenceNextText{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    width: 100%;
    font-family: DirectorsGothic;
    color: #82c1c8;
    border-bottom: solid 1px #e4e6e9;
}

.wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.image {
    height: 88px;
    width: 158px;
    object-fit: contain;
    filter: grayscale(100%);
    margin: 10px 24px;
    display: inline-block;
    line-height: 100px;
}
.image:hover {
    transform: scale(1.2);
    filter: grayscale(0%);
}

.image-container {
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.prev{
    rotate: 180deg;
    cursor: pointer;
}

.next{
    cursor: pointer;
}

.mobileNextClients{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.contactBox{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 112px 0;
    margin-top: 88px;
    background-color: #f8f8f8;
    scroll-margin-top: 24px;
}

.contactContent{
    max-width: 1008px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 52px;
}

.contentInfoBox{
    display: flex;
    flex-direction: column;
}

.contactLine{
    height: 8px;
    width: 52px;
    background-color: #afb6be;
    margin-bottom: 20px;
}

.contactTextMain{
    color: #1f1d5c;
    font-size: 42px;
    font-family: DirectorsGothic;
    margin-bottom: 54px;
}

.contactText{
    color: #1f1d5c;
    font-size: 24px;
    font-family: MontserratRegular;
}

.contactTitleInfo{
    margin-top: 40px;
    padding-bottom: 12px;
    margin-bottom: 16px;
    width: 100%;
    font-family: DirectorsGothic;
    color: #82c1c8;
    border-bottom: solid 1px #e4e6e9;
}

.contactTextInfo{
    color: #b4bbc2 !important;
    font-family: MontserratRegular;
}

.helpBox{
    background-color: #afb6be;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 112px 0;
}

.helpContent{
    max-width: 1008px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 52px;
}

.helpInfoBox{
    display: flex;
    flex-direction: column;
}

.helpLine{
    height: 8px;
    width: 52px;
    background-color: white;
    margin-bottom: 20px;
}

.helpTextMain{
    color: #1f1d5c;
    font-size: 42px;
    font-family: DirectorsGothic;
    margin-bottom: 54px;
}

.helpText{
    color: #1f1d5c;
    font-size: 24px;
    font-family: MontserratRegular;
    margin-bottom: 54px;
}

.helpImageBox{
    display: flex;
    justify-content: center;
}

.bottomBox{
    background-color: #1f1d5c;
    width: 100%;
    height: 120px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.bottomBoxContent{
    width: 100%;
    margin: 0 100px;
    max-width: 1308px;
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
}

.copyrightLinksBox{
    display: flex;
    flex-direction: row;
}

.copyright{
    padding: 0 12px;
    border-right: solid 1px white;
}

.rights{
    padding: 0 12px;
}

.link{
    padding: 0 12px;
}

.linkLines{
    padding: 0 12px;
    border-left: solid 1px white;
}

.modalBox{
    width: 890px;
    display: flex;
    flex-direction: column;
    animation: fadeIn 2s;
    background-color: white;
    height: 576px;
}

.modalCloseButtonBox{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.modalCloseButton{
    padding-top: 16px;
    padding-right: 16px;
    cursor: pointer;
}

.dialog{
    width: 100%;
    height: Calc(100% - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 3;
    background-color: rgb(31, 29, 92);
    background-color: rgba(31, 29, 92, 0.7);
    animation: fadeIn 0.5s;
}

.modalLine{
    height: 8px;
    width: 52px;
    background-color: white;
    margin-bottom: 20px;
    margin-left: 64px;
}

.modalTextMain{
    color: #1f1d5c;
    font-size: 42px;
    font-family: DirectorsGothic;
    margin-left: 64px;
}

.modatTextBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin: 30px 16px 166px 16px;
    font-size: 20px;
}

.modalImage{
    background-color: #8ccdd1 !important;
    padding-bottom: 54px;
}

.modalTextBoxFont {
    font-family: MontserratRegular;
    min-height: 245px;
}

.montserratRegular{
    font-family: MontserratRegular;
}
.loadingPage {
    position: absolute;
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ld-ripple {
    position: relative;
    width: 80px;
    height: 80px;
}

.ld-ripple div {
    position: absolute;
    border: 4px solid #1f1d5c;
    opacity: 1;
    border-radius: 50%;
    animation: ld-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ld-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes ld-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

#container {
    height:130px;
    position:relative;
    overflow:hidden;
}

.photobanner {
    position:absolute;
    top:0px;
    left:0px;
    overflow:hidden;
    white-space: nowrap;
    animation: bannermove 15s linear infinite;
}

@keyframes bannermove {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, 0);
    }
}


#container:hover .photobanner {
    animation-play-state:paused;
}