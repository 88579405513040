.contactContainer{
    display: flex;
    flex-direction: column;
}

.contactImageBox{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    padding: 24px;
    justify-items: center;
}

.contactName{
    font-family: DirectorsGothic;
    color: #82c1c8;
}

.contactPosition{
    padding-bottom: 12px;
    margin-bottom: 16px;
    color: #1f1d5c;
    font-family: MontserratRegular;
    border-bottom: solid 1px #e4e6e9;
}

.contactInfoText{
    color: #b4bbc2 !important;
    font-family: MontserratRegular;

}