@media screen and (max-width: 1100px){
    .container{
        display: flex !important;
        flex-direction: column-reverse;
        padding: 18px 0 !important;
        margin-bottom: 30px !important;
    }
    .lineBetween{
        border-left: 0 !important;
    }
    .containerReverse{
        display: flex !important;
        flex-direction: column;
        padding: 18px 0 !important;
        margin-bottom: 30px !important;
    }
    .lineBetweenReverse{
        border-right: 0 !important;
    }
    .contentBoxReverse{
        margin-left: 0 !important;
    }
}
.container{
    width: 100%;
    border-bottom: solid 1px #dcdfe2;
    border-top: solid 1px #dcdfe2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 22px;
    padding: 18px 0;
}

.contentBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.referenceCompanyName{
    color: #8acdcf;
    font-family: DirectorsGothic;
    font-size: 18px;
    margin-left: 0;
    margin-top: 10px;
}

.referenceText{
    color: #b4bbc2;
    font-family: MontserratRegular;
    max-width: 420px;
}

.referencerNameBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 32px;
}

.dash{
    width: 15px;
    height: 1px;
    background-color: #1f1d5c;
}

.referencerName{
    color: #1f1d5c;
    font-family: MontserratBold;
}

.referencerPosition{
    color: #b4bbc2;
    font-family: MontserratRegular;
    margin-left: 23px;
}

.imageBox{
    display: flex;
    align-items: center;
}

.lineBetween{
    width: 100%;
    display: flex;
    justify-content: center;
    border-left: solid 1px #dcdfe2;
}

.lineBetweenReverse{
    width: 100%;
    display: flex;
    justify-content: center;
    border-right: solid 1px #dcdfe2;
}

.contentBoxReverse{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 34px;
}

.containerReverse{
    width: 100%;
    border-bottom: solid 1px #dcdfe2;
    border-top: solid 1px #dcdfe2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 22px;
    padding: 18px 0;
}

.hidden {
    display: none;
}